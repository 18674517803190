import React from "react"
import { Link } from "gatsby"

import Styles from "./index.module.css"
import Section from "../components/Section/Section"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Impressum = () => (
  <Layout>
    <SEO title="Impressum" />
    <Section>
      <div id="impressum">
        <h1 class="mb-4">
          Impressum
        </h1>
        <p class="mb-4">
          Angaben gemäß &sect;&nbsp;5 Telemediengesetz
        </p>
        <p class="mb-4">
          Postanschrift: <br />
          KNÖPKE Bauunternehmen GmbH<br />
          Industriezentrum 80 <br />
          D-32139 Spenge <br />
        </p>
        <p class="mb-4">
          Kontakt:<br />
          Tel.: <a href="tel:+495225859771" className={Styles.nobreak}>0&nbsp;52&nbsp;25&nbsp;&ndash;&nbsp;85&nbsp;97&nbsp;71</a><br />
          Fax: <span className={Styles.nobreak}>0&nbsp;52&nbsp;25&nbsp;&ndash;&nbsp;85&nbsp;97&nbsp;73</span> <br />
          E-Mail: <a href="mailto:info@knoepke-bau.de" className={Styles.nobreak}>info@knoepke-bau.de</a><br />
        </p>
        <p class="mb-4">
          Vertreten durch: <br />
          Eckhard Knöpke und Holger Knöpke
        </p>
        <p class="mb-4">
          Eingetragen beim: <br />
          Amtsgericht Bad Oeynhausen, HRB 6930
        </p>
        <p class="mb-4">
          Die Berufshaftpflichtversicherung wurde abgeschlossen bei: <br />
          Westfälische Provinzial Versicherung AG, Münster <br />
          Geltungsbereich des Versicherungsschutzes: <br />
          Weltweit, außer USA und Kanada <br />
          Aufsichtsbehörde <br />
          Berufsgenossenschft Bau, Hannover <br />
        </p>
        <p class="mb-4">
          Umsatzsteuer-Identifikationsnummer <br />
          Ust.ID: DE125357280
        </p>
        <p class="mb-4">
          Urheberrecht:<br />
          Alle Inhalte dieser Internetseiten, insbesondere Texte, Fotografien und Grafiken, sind urheberrechtlich geschützt. Das Urheberrecht liegt beim jeweiligen Autor. Bitte fragen Sie mich, falls Sie die Inhalte dieser Internetseiten verwenden möchten. Wer gegen das Urheberrecht verstößt (z.B. Bilder oder Texte unerlaubt kopiert), macht sich gem. &sect;06 ff UrhG strafbar, wird zudem kostenpflichtig abgemahnt und muss Schadensersatz leisten (&sect;97 UrhG).
        </p>
        <p class="mb-4">
          Bildnachweis:<br />
          <span className={Styles.nobreak}>&copy;&nbsp;bonnyblum &ndash; site-196210.jpg</span><br />
          <span className={Styles.nobreak}>&copy;&nbsp;SatyaPrem &ndash; site-3304815.jpg</span><br />
        </p>
        <p class="mb-4">
          Design und Umsetzung:<br />
          <span className={Styles.nobreak}>Interweber</span><br />
          <a href="https://interweber.de" className={Styles.nobreak} rel="nofollow" title="Internetagentur Kreis Herford">Internetagentur im Kreis Herford</a><br />
        </p>        
      </div>
    </Section>
  </Layout>
)

export default Impressum
